import React from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import { EsriProvider } from 'leaflet-geosearch';
// import 'leaflet/dist/leaflet.css'
import { useStaticQuery, graphql } from "gatsby"
import './leafletmap.css'
// import Openrouteservice from "openrouteservice-js"

const LeafletMap = () =>  {
  const  lookupAddress = (address) => {
    const provider = new EsriProvider()
    const results = provider.search({ query: address })
    // results.then ( value => {
      
    //   console.log("address", address);
    //   console.log("value", value);
    //   console.log("results", results);
    //   return results
    // }, reason => {
    //   console.log("fail",reason);
    //   return null
    // })
    return results
  }

  const data = useStaticQuery(graphql`
  {
    allAirtable(filter: {table: {eq: "sites"}}) {
      edges {
        node {
          recordId
          id
          data {
            Name
            Location
            Link
            slug
            Notes
            Price
            Acres
            lat
            lon
            hours
            Pics {
              id
              url
            }
            Red_flags
            Status
          }
          }
        }
      }
    }
    `)

  const defaultProps = {
    
    position: [44.625, -69.134],
    zoom: 10,
    markerText: "looon"
  }
 
  data.allAirtable.edges.forEach(placeData => {
    const place = placeData.node.data
    const placeId = placeData.node.recordId
    if (place.lat == null && place.Location && place.Name !== `Land Map`) {
      lookupAddress(place.Location).then(function(latlon) {
        // update lat and lon
        const Airtable = require('airtable');
        const base = new Airtable({apiKey: process.env.AIRTABLE_API_TOKEN}).base('apptrXqHbwwxM0Va5');
        const timeNow = new Date().toLocaleString();
      
        base('sites').update([
          {
            "id": placeId,
            "fields": {
              "slug": `updated at ${timeNow}?`,
              "lat": `${latlon[0].y}`,
              "lon": `${latlon[0].x}`
            }
          }
        ],{typecast: true},function(err, records) {
          if (err) {
            console.error(err, place.Name);
            return;
          }
        });
      }).catch(error => {
        console.log(error);
        console.log(place);
      })
    }

    // if (place.hours == null && place.Name !== "Land Map") {

    //         // get driving distance
    //         const Matrix = new Openrouteservice.Matrix({ api_key: "5b3ce3597851110001cf62483da7c4b0050d4dc08d658c7bfd7e6487"});

    //         Matrix.calculate({
    //           locations: [[-75.231, 39.938], [place.lon, place.lat]],
    //           profile: "driving-car",
    //           range_type: ["duration","distance"],
    //           sources: ['all'],
    //           destinations: ['all']
    //         })
    //         .then(function(response) {
    //           // Add your own result handling here
    //           console.log("response", response);
    //           let hours = response.durations[0][1]/3600
    //           hours = Math.round(hours,2)

    //           console.log("duration", hours);
    //           // update lat and lon
    //           const Airtable = require('airtable');
    //           const base = new Airtable({apiKey: process.env.AIRTABLE_API_KEY}).base('apptrXqHbwwxM0Va5');              
    //           base('sites').update([
    //             {
    //               "id": placeId,
    //               "fields": {
    //                 "hours": hours
    //               }
    //             }
    //           ],{typecast: true},function(err, records) {
    //             if (err) {
    //               console.error(err, place.Name);
    //               return;
    //             }
    //           });
    //         })
    //         .catch(function(err) {
    //           var str = "An error occurred: " + err;
    //           console.log(str);
    //         });
    // }
  })


  return (
    <Map center={defaultProps.position} zoom={defaultProps.zoom} >
      <TileLayer
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
        style={{
          height: `98vh`,
          width: `100vw`
        }} />    
        
      {
        data.allAirtable.edges.map(placeData =>  {
          const place = placeData.node.data
          if (place.lat && place.Name !== `Land Map` && place.Status === "Active") {

            const placeId = placeData.node.recordId
            const placePos = [place.lat, place.lon]
            const picUrl = place.Pics ? <img src={place.Pics[0].url} alt={place.Name} /> : <span/>
            return (  
              <Marker key={`marker-${placeId}`} position={placePos} >
                <Popup>
                  <a href={place.Link} rel="noopener">
                    <h2>{place.Name}</h2>
                    <p>
                      {place.Notes} ✶ 
                      ${place.Price} ✶ {place.Acres} acres * hours hrs
                    <br />
                      {picUrl}
                    </p>
                  </a>
                </Popup>
              </Marker>
            )
          } else {
            console.log(place.Name);
            return null
            // return (
            //   <Marker  position={[39.939291798739035, -75.22976139510047]} >
            //     <Popup>
            //         <h2>not land</h2>
            //         <p>
            //           nothing here i guess just Philadelphia
            //         </p>
            //     </Popup>
            //   </Marker>
            // )
          }
        })
      }      
    </Map>
  )
}

export default LeafletMap
