import React from "react"
import Layout from "../components/layout"
import LeafletMap from "../components/leafletmap"

const IndexPage = () => (
  <Layout>
    {typeof window !== 'undefined' &&
        <LeafletMap />
      }
  </Layout>
  )

  export default IndexPage
